import React from "react"
// import { } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Splash from '../components/splash'
import Button from "../components/button"

const style = {
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 40px',
}

const IndexPage = () => (
  <Layout home>
    <SEO title="Home" />
    <Splash image="/thor.jpg">
      <div style={ style }>
        <Button link="/puppies">🐾 View available puppies! 🐾</Button>
      </div>
    </Splash>
  </Layout>
)

export default IndexPage
