import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import * as style from '../styles/theme/button.module.scss'

const Button = (props) => {
  const classes = classNames(style.button, {[style.small]: props.small})
  if (props.link) {
    return <Link className={ classes } to={ props.link }>{ props.children }</Link>
  }

  return <button className={ classes }>{ props.children }</button>
}
  
export default Button
